import React from 'react'
import { Helmet } from 'react-helmet'
import Team from '../components/ourteam/Team'
import { Hero } from '../components/shared/hero/Hero'
const Operation = () => {
  const hero = {
    head: 'WELCOME TO ABELA',
    title: 'Our Team',
    shortTitle: 'OUR TEAM',
    slogan: `High staff qualifications with expertise in the field of culinary arts and hospitality allow us to provide under all circumstances professional services`,
    href: '/ourteam',
    linkTo: '/ourteam#team',
    backGround: '/images/Teams/HeroImage.webp',
    mobBackGround: '/images/Teams/HeroImage-Mobile.webp'
  }
  const team = {
    title: 'STAFF',
    feature: 'Meet Our Team',
    description: 'Our staff gives Abela the identity it has today',
    team: [
      {
        img: 'SamehMohamed.jpg',
        name: 'Sameh Mohamed',
        position: 'Cost Control Manager'
      },
      {
        img: 'KerolosReda.jpg',
        name: 'Kerolos Reda',
        position: 'Human Resources Manager'
      },
      {
        img: 'HaithamHaroun.jpg',
        name: 'Haitham Haroun',
        position: 'Commercial Manager'
      },
      {
        img: 'MaiMekawy.jpg',
        name: 'May Mekawy',
        position: 'Business Development Senior Manager'
      },
      {
        img: 'JackWagihHaleem.jpg',
        name: 'Jack Wagih Haleem',
        position: 'Finance Senior Manager'
      },
      {
        img: 'AbdElHamidSaadAllah01.jpg',
        name: 'Abd El Hamid Saad Allah',
        position: 'Deputy Chief Exective for Financial'
      },
      {
        img: 'Karim.jpg',
        name: 'Karim Garas',
        position: 'CEO'
      },
    ]
  }
  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | OUR TEAM</title>
        
        <link rel="icon" type="image/webp" href="/images/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Hero data={hero} />
      <Team data={team} />
    </div>
  )
}

export default Operation
