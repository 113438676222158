import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import './MenuModel.css'
import Slider from 'infinite-react-carousel';
import { useSelector } from 'react-redux'

const MenuModel = ({ open, setOpen, menu }) => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  
  const [currentSlide, setCurrentSlide] = useState(0);
  const cancelButtonRef = useRef(null)
  const sliderRef = useRef<any>()
  // const [currentSlide, setCurrentSlide] = useState(0);
  const [isSliderAnimating, setIsSliderAnimating] = useState(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[70] "
        initialFocus={cancelButtonRef}
        onClose={(i) => { setOpen(i); setCurrentSlide(0) }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-[70]  bg-black bg-opacity-[0.8] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-[70] ">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`menuContainer ${isDarkMode ? "bg-[#1c1c1c]" : "bg-white"}`}>
                
                <button
                  className="close-menu absolute top-[32px] right-[7px] z-10 w-[50px] focus:outline-none focus:border-white focus:ring-1 focus:ring-white"
                  onClick={() => { setOpen(false); setCurrentSlide(0) }}
                >
                  <img src="/images/shared/close-model.svg" alt="close-model-btn" className='' />
                </button>
                <h1 className={`menuTitle ${isDarkMode ? "text-[#fff]" : "text-[#000]"}`}>{menu.name}</h1>

                {menu?.items?.length > 1 ?
                  <button
                    type="button"
                    className={`arrowButton catering-prev ${currentSlide === 0 ? isDarkMode?"disabledArrowDark":"disabledArrow" : ""}`}
                    onClick={() => {
                      if (!isSliderAnimating && currentSlide > 0) {
                        sliderRef?.current?.slickPrev();
                      }
                    }}
                    disabled={currentSlide === 0 || isSliderAnimating}
                  >
                    <img src="/images/shared/prevWhite.webp" className="prevArrow" alt="" />
                  </button>
                  : null
                }
                <Slider
                  dots
                  ref={sliderRef}
                  arrows={false}
                  className="menuSwiper "
                  beforeChange={() =>
                    setIsSliderAnimating(true)
                  }
                  afterChange={(index) => {
                    setCurrentSlide(index);
                    setIsSliderAnimating(false);
                  }}
                >
                  
                  {menu?.items?.map((item, i) => (
                    <MenuList item={item} i={i} />
                  ))}
                </Slider>
                {menu?.items?.length > 1 ?
                  <button
                    type="button"
                    className={`arrowButton catering-next ${currentSlide === menu?.items?.length - 1 ? isDarkMode?"disabledArrowDark":"disabledArrow" : ""}`}
                    onClick={() => {
                      if (!isSliderAnimating && currentSlide < menu?.items.length - 1) {
                        sliderRef?.current?.slickNext();
                      }
                    }}
                    disabled={currentSlide === menu?.items.length - 1 || isSliderAnimating}

                  >
                    <img src="/images/shared/nextWhite.webp" className="nextArrow" alt="" />
                  </button>
                  : null
                }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default MenuModel

const MenuList = ({ item, i }) => {
  
  const { isDarkMode } = useSelector((state: any) => state.dark)
  return (
    <div className='swiperBox'>
      {/* <div className='swiper-modal-subtitle flex justify-center'>
        {item.subTitle !== "" ? <>
          <h2 className={`menuSubtitle ${isDarkMode?"text-white":"text-black"}`}>
            {item.subTitle}
          </h2>
          <span className='subTitleNo'>{i + 1}</span>
        </> :
          <>
            <br />
            <br />
          </>}
      </div> */}
      <div className='flex gap-[11px] h-[80%] menu-model-image'>
        <img src={`/images/Services/menu/${item.img}`} alt={item.subTitle} className="menuPoster" />

        <div className={`menuList ${isDarkMode?"text-white":"text-black"}`}>
          {item?.array?.map((x) => (
            <div className='menuItemContainer	'>
              <div className='min-w-[57px] max-h-10'>
                <img src={`/images/Services/menu/icons/${x.icon}`} alt='' />
              </div>
              <div className='h-fit'>
                <div className='menuItem'>{x.itemName}<br />
                  <div className='desContainer'>
                    <span className={`menuItemDes ${isDarkMode?"text-[#bebebe]":"text-[#606060]"}`}>{x.descripetion}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}