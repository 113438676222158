import React, { useEffect, useState } from 'react'

function CateringCard({ item, index, handleMenu }) {
    const [imageSrc, setImageSrc] = useState(item.img);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const highQualityImage = new Image();
        highQualityImage.src = item.img;

        highQualityImage.onload = () => {
            setImageSrc(item.img);
            setIsLoading(false);
        };
    }, [item.img]);
    return (
        <div onClick={() => { handleMenu(item.menu); }} key={index} className='cursor-pointer'>
            <div className="catering-card" style={{ backgroundImage: `url(${imageSrc})`, filter: isLoading ? 'blur(20px)' : 'none' }}>

                <div className='hover'>

                    <p className={`catering-title playfair text-[28px] font-black text-[white]`}>
                        {item.title}
                    </p>
                    <p className={`catering-description inter text-[18px] font-light text-[white]`}>
                        {item.description}
                    </p>
                    <button
                        className="catering-button font-semibold"
                        onClick={() => { handleMenu(item.menu); }}
                    >
                        VIEW MENU
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CateringCard