import React, { useState, useEffect } from "react";
import {
    TETabs,
    TETabsContent,
    TETabsItem,
    TETabsPane,
} from "tw-elements-react";
import GallerySlider from '../gallery/GallerySlider'
import SwiperGallery from "./SwiperGallery";
import './Tabs.css'
import { useSelector } from 'react-redux'

export default function TabsBasic(): JSX.Element {
    const { isDarkMode } = useSelector((state: any) => state.dark)

    const galleryGrid = {
        title: 'GALLERY',
        feature: 'Photos',
        description:
            'Browse our photos to see the incredible effort we put into making every event look spectacular!',
        imgs: [
            '11.webp',
            '4.webp',
            '26.webp',
            '70.webp',
            '39.webp',
            '36.webp',
            '42.webp',
            '78.webp',
            '14.webp',
            '46.webp',
            '66.webp',
            '49.webp',
            '68.webp',
            '65.webp',
            '33.webp',
            '77.webp',
            '21.webp',
            '67.webp',
            '75.webp',
            '10.webp',
            '56.webp',
            '8.webp',
            '15.webp',
            '37.webp',
            '38.webp',
            '29.webp',
            '3.webp',
            '41.webp',
            '79.webp',
            '60.webp',
            '5.webp',
            '31.webp',
            '76.webp',
            '72.webp',
            '63.webp',
            '62.webp',
            '44.webp',
            '74.webp',
            '28.webp',
            '16.webp',
            '6.webp',
            '58.webp',
            '69.webp',
            '22.webp',
            '55.webp',
            '54.webp',
            '27.webp',
            '7.webp',
            '57.webp',
            '30.webp',
            '35.webp',
            '51.webp',
            '9.webp',
            '2.webp',
            '12.webp',
            '53.webp',
            '34.webp',
            '61.webp',
            '43.webp',
            '47.webp',
            '20.webp',
            '73.webp',
            '19.webp',
            '50.webp',
            '40.webp',
            '17.webp',
            '23.webp',
            '32.webp',
            '48.webp',
            '59.webp',
            '1.webp',
            '45.webp',
            '52.webp',
            '71.webp',
            '80.webp',
        ]
    }
    const gallerySlider = {
        title: 'GALLERY',
        feature: 'Videos',
        description:
            'We try to record as much of our catering services as possible but, as we all know, no video will do justice to the taste of our food.',
        videos: [
            {
                img: 'Video01.jpg',
                video: '/images/Gallery/coffebreak.mp4'
            },
            {
                img: 'Video02.jpg',
                video: '/images/Gallery/outdoors.mp4'
            },
            {
                img: 'Video03.jpg',
                video: '/images/Gallery/summaryreel.mp4'
            },
            {
                img: 'Video04.jpg',
                video: '/images/Gallery/HSE.mp4'
            }
        ]
    }
    const [basicActive, setBasicActive] = useState("tab1");

    const handleBasicClick = (value: string) => {
        if (value === basicActive) {
            return;
        }
        setBasicActive(value);
    };

    const [cards, setCards] = useState(false)
    const [showCount, setShowCount] = useState<null | number>(null)
    const changeBackground = () => {
        if (window.innerWidth >= 1024) {
            setShowCount(5)
            setCards(false)
        } else if (window.innerWidth >= 400) {
            setShowCount(3)
            setCards(false)
        } else {
            setShowCount(1)
            setCards(true)
        }
    }

    useEffect(() => {
        changeBackground()
        window.addEventListener('resize', changeBackground)
        return () => {
            window.removeEventListener('resize', changeBackground)
        }
    }, [showCount])

    return (

        <div className={` ${isDarkMode ? "bg-[#1c1c1c]" : "bg-[#ffffff]"} flex justify-center items-center overflow-hidden`}>
            <div className='mainContainer gallery-page'>
                <TETabs justify className="w-full pt-[85px] justify-center gap-[50px] l:gap-40">
                    <TETabsItem
                        onClick={() => handleBasicClick("tab1")}
                        active={basicActive === "tab1"}
                        className={` ${basicActive === "tab1" ?
                            `inter 
                        gallery-tab  
                        inline-flex 
                        max-h-[80px]
                        max-w-[180px]
                        items-center
                        justify-center
                        whitespace-nowrap
                        rounded-[30px]
                        border
                        border-transparent 
                        bg-[#70C2B4] 
                        md:px-20  
                        p-2 
                        md:py-2 
                        md:text-[20px]  
                        font-medium 
                        text-white 
                        shadow-sm 	`
                            :
                            `gallery-tab  
                        px-20 
                        rounded-[30px] 
                        md:py-2 
                        p-2
                        text-[20px]  
                        font-medium 
                        ${isDarkMode?"bg-[#111] text-white":"bg-gray-200 text-black "}
                        inter  
                        inline-flex 
                        max-h-[80px]
                         max-w-[180px] 
                         items-center 
                         justify-center 
                         whitespace-nowrap  
                         border 
                         border-transparent`}`}
                    >
                        PHOTOS
                    </TETabsItem>
                    <TETabsItem
                        onClick={() => handleBasicClick("tab2")}
                        active={basicActive === "tab2"}
                        className={`${basicActive === "tab2" ? 
                        `inter 
                        gallery-tab  
                        inline-flex 
                        max-h-[80px] 
                        max-w-[180px] 
                        items-center 
                        justify-center 
                        whitespace-nowrap 
                        rounded-[30px] 
                        border 
                        border-transparent 
                        bg-[#70C2B4] 
                        md:px-20  
                        p-2 
                        md:py-2 
                        md:text-[20px]  
                        font-medium 
                        text-white 
                        shadow-sm 	 ` 
                        : 
                        `gallery-tab  
                        md:px-20 
                        rounded-[30px] 
                        md:py-2 
                        p-2 
                        md:text-[20px]  
                        font-medium  
                        ${isDarkMode?"bg-[#111] text-white":"bg-gray-200 text-black "}
                        inter  
                        inline-flex 
                        max-h-[80px] 
                        max-w-[180px] 
                        items-center 
                        justify-center 
                        whitespace-nowrap  
                        border 
                        border-transparent`}`}
                    >
                        VIDEOS
                    </TETabsItem>

                </TETabs>

                <TETabsContent>
                    <TETabsPane show={basicActive === "tab1"} >
                        <SwiperGallery data={galleryGrid} showCount={showCount} cards={cards} isDarkMode={isDarkMode} />
                    </TETabsPane>
                    <TETabsPane show={basicActive === "tab2"}>
                        {basicActive === "tab2" ?
                            <GallerySlider data={gallerySlider} isDarkMode={isDarkMode} />
                            : null}
                    </TETabsPane>
                </TETabsContent>
            </div>
        </div>
    );
}