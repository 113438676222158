import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import './Error.css'
const Erorr = () => {
  return (
    
    <section className="Erorr" style={{ backgroundImage: `url(/images/error-banner01@2x.webp)` }}>
      <div className="Error-container">
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className="Erorr-title text-[100px] md:text-[160px]"
        >
          404
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 0.2 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className="inter pb-[30px] text-[36px] font-medium text-white"
        >
          Page Not Found
        </motion.p>
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 0.4 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className="inter pb-[50px] text-center text-[18px] font-light text-white"
        >
          The page you are looking for was moved, removed, renamed or might never existed
        </motion.p>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 0.6 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
        >
          <Link
            to="/"
            className="inter h-[50px] w-[150px] rounded-[30px] bg-[#70C2B4] p-[17px] text-center text-[13px] font-semibold text-white"
          >
            BACK TO HOME
          </Link>
        </motion.div>
      </div>
    </section>
  )
}

export default Erorr
