import { motion } from 'framer-motion'
import React from 'react'
import './Staffawareness.css'
export const Staffawareness = () => {
  const data = {
    img: '/images/HSE/Certificates.jpg',
    lowImg: '/low/HSE/stuffAwar.webp',
    backgroundImage: '/images/HSE/CertificatesBG.jpg',
    title: 'Site Certification',
    desc: 'Abela Catering takes pride in its commitment to quality, safety, and sustainability. We follow strict food safety and hygiene standards to ensure that our clients receive the highest quality meals prepared in a safe and sanitary environment. As part of our commitment to environment, health and quality, we are proud to hold ISO 14001, ISO 45001 and ISO 22000.'
    
  }
  return (

    <section
      className="staff-container "
      style={{
        background: `#0C0C0C url(${data.backgroundImage}) no-repeat`,
        backgroundSize: 'cover'
      }}
    >
      <div className='mainContainer'>
        <div className='main-staff-container flex flex-col sm:flex-row items-center gap-8'>

          <motion.picture
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="staff-image order-last lg:order-1"
          >
            <img src={data.img} alt={data.title} className='border border-[transparent] rounded-xl' />
          </motion.picture>
          <div className="staff-info order-1">
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="playfair text-[58px] font-black text-[#70C2B4] "
            >
              {data.title}
            </motion.p>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="inter p-2 text-[17px] font-normal text-white "
            >
              {data.desc}
            </motion.p>
          </div>
        </div>
      </div>
    </section>
  )
}
