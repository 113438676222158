import React from 'react'
import { Route, Routes } from 'react-router-dom'
import About from './pages/About'
import Services from './pages/Services'
import Sectors from './pages/Sectors'
import Hse from './pages/Hse'
import Layout from './components/shared/Layout'
import Gallery from './pages/Gallery'
import OurTeam from './pages/OurTeam'
import ContactUs from './pages/ContactUs'
import NotFound from './pages/NotFound'

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/sectors" element={<Sectors />} />
        <Route path="/foodsafety" element={<Hse />} />
        <Route path="/gallery" element={<Gallery />} /> 
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  )
}

export default App
