import React from 'react'
import { Helmet } from 'react-helmet'
import { Practices } from '../components/hse/Practices'
import { SafetyGrid } from '../components/hse/SafetyGrid'
import { Staffawareness } from '../components/hse/Staffawareness'
import { Hero } from '../components/shared/hero/Hero'
import { Whoweare } from '../components/shared/whoarewe/Whoweare'

const Hse = () => {
  const whoweare = {
    headText: 'FOOD SAFETY & HYGIENE',
    title: 'HSE',
    description: `RSI and Abela have developed a health, safety and hygiene management tool specifically for food and beverage catering and allied industries in order to assist these industries with controlling the inherent risks known to be associated with food abs water used and producing safe food and beverage through food and beverage cycle from supplier till customer delivery. Abela Egypt services is running onsite catering operation with an integrated HSE system of high level procedures, standards, structure of organization & KPI.`,
    img: '/images/HSE/KIK_7119.webp',
    id: 'foodsafety'
  }
  const hero = {
    head: 'WELCOME TO ABELA',
    title: 'Health Safety & Environment',
    shortTitle: 'HSE',
    slogan: `Abela Egypt services is running onsite catering operation with an integrated HSE system of high level procedures, standards, structure of organization & KPI`,
    href: '/foodsafety',
    linkTo: '/foodsafety#foodsafety',
    backGround: '/images/HSE/HeroImage.webp',
    mobBackGround: '/images/HSE/HeroImage-mobile.webp'
  }

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | FOOD SAFETY</title>
        <link rel="icon" type="image/webp" href="/images/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Hero data={hero} />
      <Whoweare data={whoweare} />
      <Staffawareness />
      <Practices />
      <SafetyGrid />
    </div>
  )
}

export default Hse
