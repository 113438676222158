import React from 'react'
import { Helmet } from 'react-helmet'
import Erorr from '../components/shared/Erorr'

const NotFound = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | 404</title>
        
        <link rel="icon" type="image/webp" href="/images/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Erorr />
    </div>
  )
}

export default NotFound
