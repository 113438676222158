import React from 'react'
import Footer from './layout/Footer'
import Nav from './layout/Nav'
import {
  HomeIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  InboxStackIcon,
  UserGroupIcon,
  CogIcon,
  PhotoIcon
} from '@heroicons/react/24/outline'

const Layout = ({ children }) => {
  const tabs = [
    {
      name: 'HOME',
      href: '/',
      icon: HomeIcon
    },
    {
      name: 'SERVICES',
      href: '/services',
      icon: Squares2X2Icon
    },
    {
      name: 'SECTORS',
      href: '/sectors',
      icon: InboxStackIcon
    },
    {
      name: 'HSE',
      href: '/foodsafety',
      icon: ShieldCheckIcon
    },
    {
      name: 'GALLERY',
      href: '/gallery',
      icon: PhotoIcon
    },
    {
      name: 'OUR TEAM',
      href: '/ourteam',
      icon: UserGroupIcon
    },
  ]
  return (
    <>
      <Nav tabs={tabs} />
      {children}
      <Footer tabs={tabs} />
    </>
  )
}

export default Layout
