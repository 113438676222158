import React from 'react'
import { motion } from 'framer-motion'
import './cateringSectors.css'
import { useSelector } from 'react-redux'

export const CateringSectors = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const data = {
    title: 'SECTORS',
    feature: 'Catering Sectors',
    grid: {
      oil: {
        img: '/images/Sectors/OilAndGas.jpg',
        lowImg: '/low/Sectors/OilAndGas.jpg',
        title: 'Oil & Gas',
        des: 'Providing full catering services for off shore, onshore and remotes camps.',
      },
      business: {
        img: '/images/Sectors/BusinessIndustry.jpg',
        lowImg: '/low/Sectors/BusinessIndustry.jpg',
        title: 'Business Industry',
        des: 'Providing daily meals, breakfast lunch and coffee break.',
      },
      rail: {
        img: '/images/Sectors/Railways.jpg',
        lowImg: '/low/Sectors/Railways.jpg',
        title: 'Railways',
        des: 'Providing full catering Service for the sleeping trains',
      },
      health: {
        img: '/images/Sectors/Healthcare.jpg',
        lowImg: '/low/Sectors/Healthcare.jpg',
        title: 'Healthcare',
        des: 'Providing meals for patient, companion and visitor.    ',
      },
      corp: {
        img: '/images/Sectors/Corporate.jpg',
        lowImg: '/low/Sectors/Corporate.jpg',
        title: 'Corporate',
        des: 'Providing daily meals, breakfast lunch and coffee break',
      },
      event: {
        img: '/images/Sectors/SpecialEvents.jpg',
        lowImg: '/low/Sectors/SpecialEvents.jpg',
        title: 'Special Events',
        des: 'We cater for private parties weddings and social events',
      },
      constru: {
        img: '/images/Sectors/ConstructionSites.jpg',
        lowImg: '/low/Sectors/SpecialEvents.jpg',
        title: 'Construction Sites',
        des: 'The preferred choice for construction where access to quality catering is crucial for the well-being of the workforce',
      },
    }
  }
 
  return (

    <section id="whowearegrid" className={`${isDarkMode?"bg-[#1c1c1c]":"bg-white"}`}>
      <div className='mainContainer'>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className="w-full px-[10px]"
        >
          <p className={`inter main-title-back  h-fit text-center text-[36px]  md:text-[72px] font-[900] ${isDarkMode? "text-[#252525]": "text-[#f3f3f3]"} text-[#f3f3f3] lg:text-[120px] mt-10`}>
            {data.title}
          </p>
          <p className={`playfair main-title-front md:mt-[-85px] mt-[-40px] text-center text-[30px] md:text[36px] font-[900] ${isDarkMode?"text-white":"text-[#121212]"}  lg:mt-[-140px] lg:text-[60px]`}>
            {data.feature}
          </p>
        </motion.div>
        <section className="grid-container lg:pt-16">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="oil grid-card rounded-[10px] overflow-hidden"
            style={{ backgroundImage: `url(${data.grid.oil.img})`, backgroundPosition: 'center' }}
          >
            <div className="card-overflow">
              <div className="hover">

                <p className="cardTitle playfair font-black">{data.grid.oil.title}</p>
                <p className="cardDesc inter">{data.grid.oil.des}</p>
              </div>

            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="health grid-card rounded-[10px] overflow-hidden"
            style={{
              backgroundImage: `url(${data.grid.health.img})`,
              backgroundPosition: 'center'
            }}
          >
            <div className="card-overflow">
              <div className="hover">

                <p className="cardTitle playfair font-black">{data.grid.health.title}</p>
                <p className="cardDesc inter">{data.grid.health.des}</p>
              </div>

            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="corp grid-card rounded-[10px] overflow-hidden"
            style={{
              backgroundImage: `url(${data.grid.corp.img})`,
              backgroundPosition: 'center'
            }}
          >
            <div className="card-overflow">
              <div className="hover">

                <p className="cardTitle playfair font-black">{data.grid.corp.title}</p>
                <p className="cardDesc inter">{data.grid.corp.des}</p>
              </div>

            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="rail grid-card rounded-[10px] overflow-hidden"
            style={{
              backgroundImage: `url(${data.grid.rail.img})`,
              backgroundPosition: 'left'
            }}
          >
            <div className="card-overflow">
              <div className="hover">

                <p className="cardTitle playfair font-black">{data.grid.rail.title}</p>
                <p className="cardDesc inter">{data.grid.rail.des}</p>

              </div>
            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="event grid-card rounded-[10px] overflow-hidden"
            style={{
              backgroundImage: `url(${data.grid.event.img})`,
              backgroundPosition: 'center'
            }}
          >
            <div className="card-overflow">
              <div className="hover">

                <p className="cardTitle playfair font-black">{data.grid.event.title}</p>
                <p className="cardDesc inter">{data.grid.event.des}</p>
              </div>

            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="business grid-card rounded-[10px] overflow-hidden"
            style={{ backgroundImage: `url(${data.grid.business.img})` }}
          >
            <div className="card-overflow ">
              <div className="hover">

                <p className="cardTitle playfair font-black">{data.grid.business.title}</p>
                <p className="cardDesc inter">{data.grid.business.des}</p>
              </div>

            </div>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1, delay: 0.5 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="constru grid-card rounded-[10px] overflow-hidden"
            style={{ backgroundImage: `url(${data.grid.constru.img})` }}
          >
            <div className="card-overflow ">
              <div className="hover">

                <p className="cardTitle playfair font-black">{data.grid.constru.title}</p>
                <p className="cardDesc inter">{data.grid.constru.des}</p>
              </div>

            </div>
          </motion.div>
        </section>
      </div>
    </section>
  )
}
