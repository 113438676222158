import { Swiper } from '../shared/swiper/Swiper'
import { useSelector } from 'react-redux'

export const Timeline = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  
  const items = [

    {
      year: '1986',
      description:
        'Abela Catering Services, a renowned catering company, embarked on its journey in 1986. The first phase of their operations, spanning from 1986 to 1996, was marked by establishing a strong presence in Dubai and Iraq by catering to multinational companies.',
    },
    {
      year: '1996',
      description:
        'Abela Catering Services expanded its operations to cater to a wider clientele base diversified its services to meet changing market demands.',
    },
    {
      year: '2006',
      description:
        'The third phase of Abela Catering Services evolution, spanning from 2006 to 2016, was marked by a focus on innovation and market leadership. In this phase, Abela Catering Services operated and managed all as parts of sleeping trains for Cairo to Upper Egypt for 10 years.',
    },
    {
      year: '2016',
      description:
        'The fourth phase of Abela Catering Services’ growth, spanning from 2016 to 2023, is marked by a focus on sustainability and global expansion. We became the leading catering services provider in the market.',
    },
    {
      year: '2023',
      description:
        'In conclusion, over the past four decades, Abela Catering Services has gone through four distinct phases of growth, starting with working with multinational companies in Dubai and Iraq, followed by expansion and diversification, innovation and market leadership, and finally, sustainability and global expansion. Through each phase, Abela Catering Services has remained committed to delivering high-quality catering services and staying at the forefront of the catering industry.',
    },
  ]
  return (

    <div className={`timelineConatiner ${isDarkMode? " bg-[#111]": "bg-[#f3f3f3] "}`}>
      <div className='mainContainer'>
        
        <p className={`timeline-header text-center p-12 playfair text-[58px] font-black ${isDarkMode?"text-white":"text-black"} `}>Abela History</p>
        <div className="timelineStyles">
          <Swiper items={items} isDarkMode={isDarkMode} />
        </div>
      </div>
    </div>
  )
}
