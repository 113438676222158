import React from 'react'
import { Helmet } from 'react-helmet'
import { Hero } from '../components/shared/hero/Hero'
import Tabs from '../components/gallery/Tabs'
const Clients = () => {
  const hero = {
    head: 'WELCOME TO ABELA',
    title: 'Gallery',
    shortTitle: 'GALLERY',
    slogan: `Our Full-Service Catering Includes Setting Up a Spectacular Presentation That's Perfect for Your Event`,
    href: '/gallery',
    linkTo: '/gallery#gallerygrid',
    backGround: '/images/Gallery/HeroImage.webp',
    mobBackGround: '/images/Gallery/HeroImage-mobile.webp'
  }
 
  

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>ABELA | GALLERY</title>
        
        <link rel="icon" type="image/webp" href="/images/Logos/Icon.png" sizes="16x16" />
      </Helmet>
      <Hero data={hero} />
      
      <Tabs />
    </div>
  )
}

export default Clients
