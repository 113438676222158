import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Slider from 'infinite-react-carousel'
import './GalleryModel.css'
import LazyImage from './LazyImage'

const GalleryModel = ({ open, setOpen, object, index, loading, setLoading }) => {
  const cancelButtonRef = useRef(null)
  const sliderRef = useRef<any>(null)


  useEffect(() => {
    if (index === 1 || index === 0) {
      setLoading(false)
    }
  })
  const onClose = () => {
    setOpen(false)
    setLoading(false)
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[70] "
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 z-[70]  bg-black bg-opacity-[0.8] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-[70] overflow-y-auto">
          <button
            className="close-model absolute top-5 right-5 w-[50px] z-50"
            onClick={onClose}
          >
            <img src="/images/shared/close-model.webp" alt="close-model-btn" />
          </button>
          <div className="flex min-h-full items-center justify-center lg:p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex h-[100vh] w-full  transform justify-center overflow-hidden text-left shadow-xl transition-all ">

                {loading ? <img src='/images/shared/Rolling-1s-200px.gif' alt='' style={{ width: '50px', height: '50px', position: 'absolute', top: '50%' }} /> : null}

                <div className={`galleryModel-swiper`}
                  style={{ opacity: `${loading ? "0" : "1"}` }}>
                  <button
                    type="button"
                    className="arrowButton galleryModel-prev "
                    onClick={() => {
                      sliderRef?.current?.slickPrev()
                    }}
                  >
                    <img src="/images/shared/prevWhite.webp" className="prevArrow" alt="" />
                  </button>
                  {
                    typeof object === "object" ?
                      <Slider
                        slidesToShow={1}
                        arrows={false}
                        ref={sliderRef}
                        className="galleryModel-carousel"
                        initialSlide={index}
                        afterChange={() => setLoading(false)}
                      >
                        {Object.keys(object).map((key, idx) => (
                          <div className="galleryModel-card" key={idx}>
                            <LazyImage src={`/images/Gallery/grid/${object[key]}`} alt=""/>
                          </div>
                        ))}
                      </Slider>
                      :
                      <Slider
                        slidesToShow={1}
                        arrows={false}
                        ref={sliderRef}
                        className="galleryModel-carousel"
                        initialSlide={index}
                        afterChange={() => setLoading(false)}
                      >
                        {object.sort((a, b) => Number(a.split(".")[0]) - Number(b.split(".")[0])).map((item, idx) => (
                          <div className="galleryModel-card" key={idx}>
                            <LazyImage src={`/images/Gallery/grid/${item}`} alt=""/>
                          </div>
                        ))}
                      </Slider>
                  }
                  <button
                    type="button"
                    className="arrowButton galleryModel-next"
                    onClick={() => {
                      sliderRef?.current?.slickNext()
                    }}
                  >
                    <img src="/images/shared/nextWhite.webp" className="nextArrow" alt="" />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default GalleryModel
