import React, { useEffect, useRef, useState } from 'react';
import Slider from 'infinite-react-carousel';
import './Swiper.css';
import '../../about/Timeline.css';



export const Swiper = ({ items, isDarkMode }: any) => {
  const sliderRef = useRef<any>();
  const [showCount, setShowCount] = useState(0);
  const [fade, setFade] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSliderAnimating, setIsSliderAnimating] = useState(false);

  const handleIndexChange = (index: number) => {
    if (sliderRef.current) {
      setCurrentSlide(index);
      setFade(false);
      setIsSliderAnimating(false);
    }
  };

  const handleFadeItem = () => {
    if (currentSlide === 0) {
      setFade(false);
      setIsSliderAnimating(false);
    } else {
      setFade(true);
      setIsSliderAnimating(true);
    }
  };

  const handleChangeBackground = () => {
    if (window.innerWidth >= 767) {
      setShowCount(3);
    } else if (window.innerWidth >= 320) {
      setShowCount(1);
    } else {
      setShowCount(1);
    }
  };

  useEffect(() => {
    setCurrentSlide(0);
    handleChangeBackground();
    window.addEventListener('resize', handleChangeBackground);
    return () => {
      window.removeEventListener('resize', handleChangeBackground);
    };
  }, []);

  return (
    <div className="swiper-container">
      <div className={`swiper-list`}>
        <Slider
          slidesToShow={showCount}
          arrows={false}
          ref={sliderRef}
          centerMode={true}
          afterChange={handleIndexChange}
          beforeChange={handleFadeItem}
          className="slider-carousel"
          initialSlide={currentSlide}
          duration="50"
        >
          {items?.map((item, index) => (
            <div className="timelineDate" key={index}>
              <p className={`timelineMonth inter monthActive uppercase`}></p>
              <p
                className={`timelineYear abril ${isDarkMode ? 'text-white' : 'text-[#121212]'} yearActive`}
              >
                {item.year}
              </p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="timelineDown">
        <button
          type="button"
          className={`arrowButton arrowLeft ${
            currentSlide === 0  ? (isDarkMode ? 'disabledArrowDark' : 'disabledArrow') : ''
          }`}
          onClick={() => {
            if (!isSliderAnimating && currentSlide > 0) {
              sliderRef.current?.slickPrev();
            }
          }}
          disabled={currentSlide === 0 || isSliderAnimating}
        >
          <img src="/images/shared/prevWhite.webp" className="prevArrow" alt="" />
        </button>
        <div className="dotContainer">
          {[...Array(showCount)].map((_item, index) => (
            <div className={`dotLines`} key={index}>
              <div
                className={`dot ${
                  showCount === 5 && index === 2
                    ? 'dotActive'
                    : showCount === 3 && index === 1
                    ? 'dotActive'
                    : showCount === 1
                    ? 'dotActive'
                    : ''
                }`}
              ></div>
            </div>
          ))}
        </div>
        <button
          type="button"
          className={`arrowButton arrowRight ${
            currentSlide === items?.length - 1 
              ? isDarkMode
                ? 'disabledArrowDark'
                : 'disabledArrow'
              : ''
          }`}
          onClick={() => {
            if (!isSliderAnimating && currentSlide < items?.length - 1) {
              sliderRef.current?.slickNext();
            }
          }}
          disabled={currentSlide === items?.length - 1 || isSliderAnimating}
        >
          <img src="/images/shared/nextWhite.webp" className="nextArrow" alt="" />
        </button>
      </div>
      <div
        style={{ height: 'fit-content' }}
        className={`description h-full flex justify-center py-12 ${
          fade ? 'opacity-0 ease-out duration-300' : 'opacity-100 ease-in duration-300'
        }`}
      >
        <p
          className={`inter ${isDarkMode ? 'text-[#bebebe]' : 'text-[#6E6E6E]'} lg:max-w-[800px] text-center text-[18px] font-normal `}
        >
          {items[currentSlide].description}
        </p>
      </div>
    </div>
  );
};
