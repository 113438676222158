import { motion } from 'framer-motion'
import React from 'react'
import './SafetyGrid.css'
import { useSelector } from 'react-redux'

export const SafetyGrid = () => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const data = [
    {
      img: 'foodTemp.webp',
      title: 'Monitoring Food Temperature',
      des: 'High risk floods are foods where bacteria may multiply if the food is stored at the wrong temperature. Generally bacteria will grow & multiply in the food at between 5 C and 65 C so this is the danger zone to avoid or minimize.'
    },
    {
      img: 'stuffTraining.webp',
      title: 'Regular Staff Training',
      des: 'Abela Egypt covers a range of food hygiene, healthy and safety subjects, from the basics of food handling, to more advanced health & safety regulations and standards and HACCP training, to ensure that our team operates safely.'
    },
    {
      img: 'StaffAwareness.jpg',
      title: 'Staff Awareness',
      des: 'We can assure you that Abela employs the best HACCP-based and sanitation training programs in the industry to keep kitchen and associated staff aware of their responsibility to maintain food quality. We ensure our staffs are apprised of the requirements in food-service and kitchen areas.'
    }
  ]
  return (

    <section className={`safetygrid-container ${isDarkMode?"bg-[#111]":"bg-[#f3f3f3]"}`}>
      <div className='mainContainer'>
        {data.map((item, index) => (
          <div className="safetybox-container" key={index}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`safetybox-image ${index === 1 ? 'sm:order-last' : ""}`}
            >
              <img src={`/images/HSE/${item.img}`} alt={item.title} className="w-full" loading='lazy'/>
            </motion.div>
            <div className="safetybox-info px-[20px] lg:px-[32px]">
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2, delay: 0.2 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                className={`safetybox-title playfair pt-[20px]  lg:pt-[111px] pb-[30px] text-[40px] font-black ${isDarkMode?"text-white":"text-black"}  md:text-[58px]`}
              >
                {item.title}
              </motion.p>
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.2, delay: 0.4 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 }
                }}
                className={`safetybox-des pb-20 lg:pb-0 inter text-[17px] font-normal ${isDarkMode?"text-[#bebebe]":" text-[#6E6E6E]"}`}
              >
                {item.des}
              </motion.p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
