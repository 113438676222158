import { createSlice } from '@reduxjs/toolkit';

// Function to retrieve the dark mode state from local storage
const loadDarkModeFromLocalStorage = () => {
    const storedDarkMode = localStorage.getItem('darkMode');
    return storedDarkMode === 'true'; // Convert the string to a boolean
};

const initialState = {
    isDarkMode: loadDarkModeFromLocalStorage() || false, // Load from local storage or default to false
};

const darkSlice = createSlice({
    name: 'dark',
    initialState,
    reducers: {
        changeDarkMode: (state, action) => {
            state.isDarkMode = action.payload;

            // Update local storage when the dark mode is changed
            localStorage.setItem('darkMode', action.payload);
        },
    },
});

export const { changeDarkMode } = darkSlice.actions;

export default darkSlice.reducer;
