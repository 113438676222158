import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import './SwiperGallery.css';
import SwiperCore, { Navigation, Pagination, A11y, EffectCards, Virtual } from 'swiper';
import GalleryModel from './GalleryModel';
import LazyImage from './LazyImage';
import { motion } from 'framer-motion'

SwiperCore.use([Navigation, Pagination, A11y, EffectCards, Virtual]);

const SwiperGallery = ({ data, showCount, cards, isDarkMode }) => {
    const [activeIndex, setActiveIndex] = useState(0); // Assuming you want to start from the first image
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [visibleItems, setVisibleItems] = useState(5);

    useEffect(() => {
        // Determine the number of visible items based on screen size
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            setVisibleItems(3);
        } else {
            setVisibleItems(5);
        }
    }, []);

    const onSlideClick = (index) => {
        console.log(index)
        setActiveIndex(index);
        setOpen(true);
        setLoading(true); // You may want to set this to false once the image is loaded.
    };

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex);
    };



    return (
        <section>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1 }}
                variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 }
                }}
                className="w-full pt-[50px]  md:pt-[70px] "
            >
                <p className={`inter main-title-back h-fit text-center  text-[60px] font-[900] ${isDarkMode ? "text-[#252525]" : "text-[#f3f3f3]"}  lg:text-[120px]`}>
                    {data.title}
                </p>
                <p className={`playfair main-title-front mt-[-60px] text-center text-[50px] font-[900] ${isDarkMode ? "text-white" : "text-[#121212]"}  lg:mt-[-140px] lg:text-[60px]`}>
                    {data.feature}
                </p>
                {/* <p className={`inter mx-auto mt-8 mb-10 text-center text-[15px] md:text-[16px] lg:text-[18px] ${isDarkMode?"text-[#bebebe]":"text-[#6e6e6e]"}  lg:mt-8 lg:mb-0 md:px-[20%]`}>
          {data.description}
        </p> */}
            </motion.div>
            <div className="SwiperGallery pt-[60px]">

                {cards ? (

                    <>

                        <button
                            type="button"
                            className="swiperArrow prevSwiper"
                        >
                            <img
                                src="/images/shared/prevWhite.webp"
                                className="prevArrow"
                                alt=""
                            />
                        </button>
                        <Swiper
                            modules={[Navigation, Pagination, A11y, EffectCards, Virtual]}
                            navigation={{
                                nextEl: '.nextSwiper',
                                prevEl: '.prevSwiper'
                            }}
                            lazy={{
                                loadPrevNext: true,
                                loadPrevNextAmount: 2,
                            }}
                            effect="slide"
                            loop
                            onSlideChange={handleSlideChange}
                            centeredSlides
                            initialSlide={activeIndex}
                            slidesPerView={1}
                        >
                            {data.imgs
                                .sort((a, b) => Number(a.split(".")[0]) - Number(b.split(".")[0]))
                                .map((item, index) => (
                                    <SwiperSlide key={index} virtualIndex={index}>
                                        <button
                                            onClick={() => onSlideClick(index)}
                                            className={`swiper-slide-content ${index === activeIndex ? 'active w-full h-full' : ''
                                                }`}
                                        >
                                            <LazyImage src={`/images/Gallery/grid/${item}`} alt={item} />
                                        </button>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                        <button
                            type="button"
                            className="swiperArrow  nextSwiper"
                        >
                            <img
                                src="/images/shared/nextWhite.webp"
                                className="nextArrow"
                                alt=""
                            />
                        </button>
                    </>

                ) : (
                    <>
                        {showCount !== null ? (<>

                            <button
                                type="button"
                                className="swiperArrow  prevSwiper"
                            >
                                <img
                                    src="/images/shared/prevWhite.webp"
                                    className="prevArrow"
                                    alt=""
                                />
                            </button>
                            <Swiper
                                modules={[Navigation, Pagination, A11y, EffectCards, Virtual]}
                                spaceBetween={50}
                                slidesPerView={visibleItems}
                                speed={1000}
                                effect="slide"
                                loop
                                lazy={{
                                    loadPrevNext: true,
                                    loadPrevNextAmount: 2,
                                }}
                                navigation={{
                                    nextEl: '.nextSwiper',
                                    prevEl: '.prevSwiper'
                                }}
                                onSlideChange={handleSlideChange}
                                centeredSlides
                                initialSlide={activeIndex}
                            >
                                {data.imgs
                                    .sort((a, b) => Number(a.split(".")[0]) - Number(b.split(".")[0]))
                                    .map((item, index) => (
                                        <SwiperSlide key={index} virtualIndex={index}>
                                            <button
                                                onClick={() => onSlideClick(index)}
                                                className={`swiper-slide-content ${index === activeIndex ? 'active w-full h-full' : ''
                                                    }`}
                                            >
                                                <LazyImage src={`/images/Gallery/grid/${item}`} alt={item} />
                                            </button>
                                        </SwiperSlide>
                                    ))}
                            </Swiper>
                            <button
                                type="button"
                                className="swiperArrow  nextSwiper"
                            >
                                <img
                                    src="/images/shared/nextWhite.webp"
                                    className="nextArrow"
                                    alt=""
                                />
                            </button>
                        </>
                        ) : null}
                    </>
                )}

                <GalleryModel
                    open={open}
                    setOpen={setOpen}
                    object={data.imgs}
                    index={activeIndex}
                    loading={loading}
                    setLoading={setLoading}
                />
            </div>
        </section>
    );
};

export default SwiperGallery;
