import React, { useState, useEffect, useRef } from 'react';

interface LazyImageProps {
    src: string;
    alt: string;
}

const LazyImage: React.FC<LazyImageProps> = ({ src, alt }) => {
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const imgRef = useRef<HTMLImageElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setImageSrc(src);
                    if (imgRef.current) {
                        observer.unobserve(imgRef.current);
                    }
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, [src]);

    return <img ref={imgRef} src={imageSrc} alt={alt} />;
};

export default LazyImage;
