import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
function PracticesImageCard({ item, index }) {
    const [imageSrc, setImageSrc] = useState(item);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const highQualityImage = new Image();
        highQualityImage.src = item;

        highQualityImage.onload = () => {
            setImageSrc(item);
            setIsLoading(false);
        };
    }, [item]);
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2, delay: index * 0.05 }}
            variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
            }}
            key={index}
            className="practice-photo"
        >
            <img
                src={`${imageSrc}`}
                alt={`step${index + 1}`}
                style={{ borderRadius: '10px', filter: isLoading ? 'blur(20px)' : 'none' }}
            />
        </motion.div>
    )
}

export default PracticesImageCard