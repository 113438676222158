import React from 'react'
import './Whoweare.css'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'

export const Whoweare = ({ data }) => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  return (

    <div className={`Whoweare ${isDarkMode?"bg-[#1c1c1c]":"bg-white"}`} id={data.id}>
      <div className='mainContainer'>
        <div className="whoWeAreContainer">
          <div className="left">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.2, delay: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className="head"
            >
              <div className="line"></div>
              <div className="inter text-[14px] font-semibold text-[#70C2B4] ">{data.headText}</div>
            </motion.div>
            <motion.h2
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.75, delay: 0.2 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`left-title playfair text-[58px] ${isDarkMode? "text-white":"text-black"} font-black `}
            >
              {data.title}
            </motion.h2>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false }}
              transition={{ duration: 0.2, delay: 0.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 }
              }}
              className={`inter max-w-[604px] text-[18px] font-normal ${isDarkMode? "text-[#bebebe]": "text-[#6E6E6E]"} `}
            >
              {data.description}
            </motion.p>
          </div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.75, delay: 0.25 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 }
            }}
            className="right"
          >
            <img src={data.img} alt={data.title} className='border rounded-xl' loading='lazy'/>
          </motion.div>
        </div>
      </div>
    </div>
  )
}
