import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { motion } from 'framer-motion'
import './Hero.css'
import { useSelector } from 'react-redux'

export const Hero = ({ data }) => {
  const { isDarkMode } = useSelector((state: any) => state.dark)
  const position = data.title.toLowerCase() === 'sectors' ? 'right' : 'center'
  const titleSize =
    data.shortTitle.toLowerCase() === 'hse' || data.shortTitle.toLowerCase() === 'contact us'
      ? 'text-[45px]'
      : 'text-[80px]'

  const scrollWithOffset = (el: any) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -80
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }
  

  const isMobile = window.innerWidth <= 768; // You can adjust this threshold as needed
console.log(isMobile)
  
  return (


    <section
      className="HeroContainer"
      style={{ backgroundImage: `url(${isMobile? data.mobBackGround: data.backGround})`, backgroundPosition: position, backgroundColor: isDarkMode? "#111": "" }}
    >
      <div className="HeroContent">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className="Blue mb-3"
        ></motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 0.2 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          style={{ textShadow: '0 0 40px rgba(0,0,0,0.4)' }}
          className="inter text-[14px] font-semibold text-white "
        >
          {data.head}
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 0.5 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          style={{ textShadow: '0 0 40px rgba(0,0,0,0.4)' }}

          className={`playfair ${titleSize} text-center font-black text-white`}
        >
          {data.title}
        </motion.div>
        
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 1 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 }
          }}
          className={`RouteHome mt-5 ${isDarkMode ? "bg-[#1c1c1c]" : "bg-[#ffffff]"}`}
        >
          <Link to="/" className="inter text-[13px] font-medium text-[#70C2B4]">
            HOME
          </Link>
          <p className="inter text-[13px] font-medium text-[#70C2B4]">{`>`}</p>
          <Link to={data.href} className={`inter text-[13px] font-medium ${isDarkMode ? "text-white" : "text-[#121212]"} `}>
            {data.shortTitle.toUpperCase()}
          </Link>
        </motion.div>

        <HashLink
          smooth
          to={data.linkTo}
          scroll={(el: any) => scrollWithOffset(el)}
          className="scrollDownContainer"
          onClick={() => { }}
        >
          <div className="mousey ">
            <div className="scroller"></div>
          </div>
        </HashLink>
      </div>
    </section>
  )
}
